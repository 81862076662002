import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./NovaBadge.css";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from "@material-ui/icons/Help";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

function NovaBadge(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",

      "& > *": {
        margin: theme.spacing(1),
        float: "left",
        padding: "3px 8px",
      },
    },
    customWidth: {
      maxWidth: 500,
    },
  }));

  const classes = useStyles();
  const longText = `
AI Safety Score: 1 - least harmful, 2 - may cause alergic reactions in specific groups, 
3 - better avoided as may cause hyperactivity or promotes inflamatory reactions 4 - avoid as may cause cancer, asthma, exema. Usually banned in many countries.
`;

  return (
    <div className={classes.root}>
      <Paper>
        <Tooltip title={longText}>
          <Typography variant="subtitle2" gutterBottom>
            AI Safety Score &copy;
          </Typography>
        </Tooltip>

        <div
          className={"nova green " + (props.selected == 1 ? "selected" : "")}
        >
          1
        </div>
        <div
          className={"nova yellow " + (props.selected == 2 ? "selected" : "")}
        >
          2
        </div>
        <div
          className={"nova orange " + (props.selected == 3 ? "selected" : "")}
        >
          3
        </div>
        <div className={"nova red " + (props.selected == 4 ? "selected" : "")}>
          4
        </div>
      </Paper>
    </div>
  );
}

export default NovaBadge;

import { handleResponse, handleError } from "./apiUtils";

export function search(code) {
  console.log("API:Get res " + code);
  const accountId = sessionStorage.getItem("account_id");
  return fetch(process.env.REACT_APP_API_URL + "/search/" + code, {
    method: "GET",
    headers: { "content-type": "application/json", accountId: accountId },
  })
    .then((response) => {
      if (!response.ok) throw new Error("Network response was not ok.");

      return response.json().then((jobs) => {
        if (jobs === null) throw new Error("job not found: ");
        return jobs;
      });
    })
    .catch(handleError);
}

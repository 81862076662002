import React from "react";

import Typography from "@material-ui/core/Typography";

import Link from "@material-ui/core/Link";

function About() {
  return <div>About addinf.com</div>;
}

export default About;

import logo from "./logo.svg";
import "./App.css";
import { Route, Switch, Redirect } from "react-router-dom";

import SearchPage from "./components/search/SearchPage";
import Header from "./components/common/Header";
import Copyright from "./components/common/Copyright";
import About from "./components/common/About";
//import ReactGA from "react-ga";

function App() {
  //ReactGA.initialize("G-X35GG451DY");
  return (
    <div className="App">
      <Header />

      <Switch>
        <Route exact path="/about" component={About} />
        <Route path="/:code" component={SearchPage} />
        <Route path="/" component={SearchPage} />
      </Switch>
      <Copyright />
    </div>
  );
}

export default App;

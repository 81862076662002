import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import NovaBadge from "../common/nova/NovaBadge";
//import ReactGA from "react-ga";

function Results(props) {
  // ReactGA.pageview(window.location.pathname + window.location.search);
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
    },
  }));

  const classes = useStyles();
  const preventDefault = (event) => event.preventDefault();

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        {props.result.code} - {props.result.name}
      </Typography>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body2" gutterBottom>
            {props.result.category}. {props.result.purpose}
          </Typography>
        </Grid>

        {props.result.safetyRating && (
          <Grid item xs={12}>
            <NovaBadge selected={props.result.safetyRating} />
          </Grid>
        )}
        {props.result.summary && (
        <Grid item xs={10} sm={8} lg={6}>
          <Typography variant="h6">Summary</Typography>
          <Typography variant="body2" gutterBottom>
            {props.result.summary}
          </Typography>
        </Grid>
        )}

        {props.result.uses && (
          <Grid item xs={9}>
            <Typography variant="h6">Uses</Typography>
            <Typography variant="body2" gutterBottom>
              {props.result.uses}
            </Typography>
          </Grid>
        )}

        {props.result.safety && (
          <Grid item xs={9}>
            <Typography variant="h6">Safety</Typography>
            <Typography variant="body2" gutterBottom>
              {props.result.safety}
            </Typography>
          </Grid>
        )}

        {props.result.efsaLink && (
          <Grid item xs={9}>
            <Typography variant="h6">EFSA</Typography>
            <Typography variant="body2" gutterBottom>
              <Link
                href={props.result.efsaLink}
                variant="body2"
                target="_blank"
              >
                {props.result.efsaLink}
              </Link>
            </Typography>
          </Grid>
        )}

        {props.result.studies && (
          <Grid item xs={9}>
            <Typography variant="h6">Studies</Typography>
            <Typography variant="body2" gutterBottom></Typography>
          </Grid>
        )}

        {props.result.links && props.result.links.length !== 0 && (
          <Grid item xs={12}>
            <Typography variant="h6">Links</Typography>
            <Typography className={classes.root} component={"span"}>
              <ol>
                {props.result.links.map((lnk, idx) => {
                  return (
                    <li key={idx}>
                      <Link href={lnk.url} variant="body2" target="_blank">
                        {lnk.name}
                      </Link>
                    </li>
                  );
                })}
              </ol>
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default Results;

import Container from "@material-ui/core/Container";
import fetch from "cross-fetch";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Results from "../results/Results";
import { search } from "../../api/SearchApi";
//import ReactGA from "react-ga";
import logo from "../../addinf_logo.png";

import { useLocation, useHistory, useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  autocomplete: {
    textAlign: "center",
    align: "center",
    justifyContent: "center",
    width: "100%",
  },

  paperRes: {
    padding: theme.spacing(2),
    textAlign: "left",
  },

  padding: {
    margin: 0,
  },
}));

function SearchPage(props) {
  const location = useLocation();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState({});
  const [textValue, setTextValue] = React.useState("");
  const [result, setResult] = React.useState();
  const [sendToServer, setSendToServer] = React.useState(false);
  const [showResults, setShowResults] = React.useState(false);
  const loading = open && options.length === 0;

  const classes = useStyles();
  let { code } = useParams();

  //ReactGA.pageview(window.location.pathname + window.location.search);

  React.useEffect(() => {
    console.log("CODE FROM USEPARAMSE IS :" + code);
    if (props.match.params.code) {
      console.log("Got param: " + props.match.params.code);
      sendSearchRequest(props.match.params.code);
    } else {
      console.log("NO PARAMS: " + props.match.params.code);
    }

    let active = true;

    if (!loading) {
      return undefined;
    }

    if (sendToServer) {
      (async () => {
        const response = await fetch(
          process.env.REACT_APP_API_URL + "/names/" + textValue
        );
        // await sleep(1e3); // For demo purposes.
        const names = await response.json();

        if (active) {
          setOptions(names);
        }
      })();
    }
    return () => {
      active = false;
    };
  }, [sendToServer]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  function selectHandler(value) {
    console.log("SELECT HANDLER");
    console.log("Value selected: " + value);

    if (value && value.length >= 3) {
      setTextValue(value);
      setSendToServer(true);
    } else {
      setSendToServer(false);
      setShowResults(false);
      setOptions([]);
    }
    // setSearchTerm(value);
  }

  function searchHandler() {
    console.log("Searching for ");
    console.log(searchTerm);
    sendSearchRequest(searchTerm.code);
  }

  function sendSearchRequest(code) {
    search(code).then((res) => {
      console.log("Results");
      // console.log(res);

      setResult(res);
      setShowResults(true);
      rewriteUrl(code);
    });
  }

  function rewriteUrl(code) {
    props.history.push("/" + code);
  }

  function onChangeHandler(val) {
    console.log("ON CHANGE HANDLER");
    console.log(val);

    setSearchTerm(val);
    if (val) {
      sendSearchRequest(val.code);
    }
  }

  let resultsGrid = null;
  if (result && showResults) {
    resultsGrid = (
      <Grid item xs={12}>
        <Paper className={classes.paperRes}>
          <Results result={result} />
        </Paper>
      </Grid>
    );
  } else {
    resultsGrid = null;
  }

  return (
    <Grid
      container
      spacing={3}
      justify="center"
      alignItems="center"
      className={classes.padding}
    >
      <Grid item xs={12}>
        <Box paddingTop={2} />
        <Link href="/" color="inherit">
          <img src={logo} />
        </Link>
      </Grid>

      <Grid container align="center" justify="center">
        <Grid item xs={10} sm={8} lg={6}>
          <Autocomplete
            id="asynchronous-demo"
            className={classes.autocomplete}
            open={open}
            autoComplete={true}
            autoSelect={true}
            name="name"
            selectOnFocus
            clearOnBlur
            freeSolo
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
              //  setShowResults(false);
            }}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => {
              // e.g value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.termName;
            }}
            onChange={(event, newValue, reason) => {
              console.log("REASON:");
              console.log(reason);
              if (reason !== "blur" && reason !== "clear") {
                onChangeHandler(newValue);
              }
            }}
            onInputChange={(event, newInputValue, reason) => {
              //setInputValue(newInputValue);
              if (reason !== "clear") {
                selectHandler(newInputValue);
              }
            }}
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                select={false}
                label="Search for E number or other name"
                variant="outlined"
                value={props.id}
                name="name"
              />
            )}
          />
        </Grid>

        <Box paddingTop={5} paddingBottom={5} />
      </Grid>

      {resultsGrid}
    </Grid>
  );
}

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default SearchPage;
